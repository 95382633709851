<template>
    <div>
        <v-tooltip left v-if="showButtons.edit">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on" 
                    :to="{ name: link, params: { id: id }}"
                >
                    <v-icon small @click="customActionEdit(id)">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.editarRevisao">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on" 
                    :to="{ name: link, params: { id: id }}"
                >
                    <v-icon small @click="customActionEdit(id)">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Gerar Revisao</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.delete">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmDelete(id)">mdi-delete</v-icon>
                </v-btn>
            </template>
            <span>Excluir</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cancel">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCancel(id)">mdi-flask-empty-off</v-icon>
                </v-btn>
            </template>
            <span>Inativar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.active">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmActive(id)">mdi-account-reactivate-outline</v-icon>
                </v-btn>
            </template>
            <span>Ativar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.approve">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmApprove(id)">mdi-account-check-outline</v-icon>
                </v-btn>
            </template>
            <span>Aprovar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.resetarSenha">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmResetarSenha(id)">mdi-lock-reset</v-icon>
                </v-btn>
            </template>
            <span>Resetar Senha</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.detalhes">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="showDetalhes(id)">mdi-text-search</v-icon>
                </v-btn>
            </template>
            <span>Visualizar Detalhes</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cancelarCotacao">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCancelCotacao(id)">mdi-window-close</v-icon>
                </v-btn>
            </template>
            <span>Cancelar Cotação</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.downloadCotacao">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmDownloadCotacao(id)">mdi-file-download-outline</v-icon>
                </v-btn>
            </template>
            <span>Download da Cotação</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.visualizarHistorico">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="visualizarHistorico(id)">mdi-history</v-icon>
                </v-btn>
            </template>
            <span>Histórico de Alterações</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.criarPedido">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCriarPedido(id)">mdi-folder-plus-outline</v-icon>
                </v-btn>
            </template>
            <span>Criar Pedido</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.validar">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmValidar(id)">mdi-playlist-check</v-icon>
                </v-btn>
            </template>
            <span>Validar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.clonar">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmClone(id)">mdi-content-duplicate</v-icon>
                </v-btn>
            </template>
            <span>Clonar</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.voltarPedido">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmVoltarPedido(id)">mdi-backup-restore</v-icon>
                </v-btn>
            </template>
            <span>Voltar pedido para integração com o TOTVS</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.consultarLog">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="consultarLog(id)">mdi-clipboard-text-clock-outline</v-icon>
                </v-btn>
            </template>
            <span>Consultar Log</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.consultarAnexos">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="consultarAnexos(id)">mdi-paperclip</v-icon>
                </v-btn>
            </template>
            <span>Verificar Anexos</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.copiar">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="copiar(id)">mdi-content-copy</v-icon>
                </v-btn>
            </template>
            <span>Copiar Link</span>
        </v-tooltip>

        <v-tooltip left v-if="showButtons.cancelarPedido">
            <template v-slot:activator="{ on }">
                <v-btn 
                    class="btnActions"
                    icon 
                    small 
                    outlined
                    v-on="on"
                >
                    <v-icon small @click="confirmCancelPedido(id)">mdi-window-close</v-icon>
                </v-btn>
            </template>
            <span>Cancelar Pedido</span>
        </v-tooltip>

        <ActionResetUsuarioSenha 
            :showDialogResetUsuarioSenha.sync="dialogResetarUsuarioSenha.show"
            :id="dialogResetarUsuarioSenha.id"
        />
        
    </div>
</template>

<script>
    import ActionResetUsuarioSenha from "@/components/Layout/ActionResetUsuarioSenha";

    
    export default {

        components: {
            ActionResetUsuarioSenha: ActionResetUsuarioSenha
        },

        props: {
            link: {
                type: String,
                default: ""
            }, 

            id: {
                type: Number,
                default : 0
            },

            showButtons: {
                type: Object,
                edit: true,
                editarRevisao: true,
                delete: true,
                cancel: true,
                active: true,
                approve: true,
                resetarSenha: true,
                detalhes: true,
                cancelarCotacao: true,
                downloadCotacao: true,
                visualizarHistorico: true,
                criarPedido: true,
                validar: true,
                clonar: true,
                copiar: true,
                voltarPedido: true,
                consultarLog: true,
                consultarAnexos: true,
                cancelarPedido: true,
            }
        },

        data: () => ({

            dialogResetarUsuarioSenha: {
                show: false,
                id: 0
            },

        }),

        methods: {
            customActionEdit(id) {
                this.$emit("customActionEdit", id);
            },
            
            confirmDelete(id) {
                this.$emit("confirmDelete", id);
            },

            confirmCancel(id) {
                this.$emit("confirmCancel", id);
            },

            confirmActive(id) {
                this.$emit("confirmActive", id);
            },

            confirmApprove(id) {
                this.$emit("confirmApprove", id);
            },

            confirmResetarSenha(id) {

                this.dialogResetarUsuarioSenha = {
                    show: true,
                    id: id
                };
            },

            showDetalhes(id) {
                this.$emit("showDetalhes", id);
            },

            confirmCancelCotacao(id) {
                this.$emit("confirmCancelCotacao", id);
            },

            confirmDownloadCotacao(id) {
                this.$emit("confirmDownloadCotacao", id);
            },

            visualizarHistorico(id) {
                this.$emit("visualizarHistorico", id);
            },

            confirmCriarPedido(id) {
                this.$emit("confirmCriarPedido", id);
            },

            confirmValidar(id) {
                this.$emit("confirmValidar", id);
            },

            confirmClone(id) {
                this.$emit("confirmClone", id);
            },

            copiar(id) {
                this.$emit("copiar", id);
            },

            confirmVoltarPedido(id) {
                this.$emit("confirmVoltarPedido", id);
            },

            consultarLog(id) {
                this.$emit("consultarLog", id);
            },

            consultarAnexos(id) {
                this.$emit("consultarAnexos", id);
            },

            confirmCancelPedido(id) {
                this.$emit("confirmCancelPedido", id);
            },
        }
    };
</script>